<template>
  <div class="content">
    <div class="title-actions">
      <h5 class="title">{{ division.name }} - {{ $t('createLiguilla') }}</h5>
      <div class="btns">
        <b-button @click="$router.go(-1)">
          <i class="fa fa-arrow-left"></i>
        </b-button>
      </div>
    </div>
    <hr>
    <div class="wrapper-liguilla">
      <form class="rounds" v-for="(round, i) in fixture" :key="`round-${i}`" @submit.prevent="save(i + 1)" :class="setClassToRound(i)">
        <h6 class="text-center">{{ $tc('round', 1) | toCapitalize }} {{ i + 1 }}</h6>
        <div class="match" v-for="(match, j) in round.round" :key="`match-${j}`">
          <div class="w-100">
              <span class="number">{{ j + 1 }}</span>
            <div class="team">
              <multiselect v-model="selectedTeam[i].round[j].match.team_local" id="team" label="name" track-by="name" open-direction="bottom" :options="teams" :multiple="false" :searchable="true" :internal-search="false" :clear-on-select="false" :close-on-select="true" :max-height="600" :show-no-results="false" :hide-selected="true" :placeholder="$t('selectOption')" deselectLabel="" selectLabel="" selectedLabel=""></multiselect>
            </div>
            <div class="team" v-if="match.team_visit !== 'FREE'">
              <multiselect v-model="selectedTeam[i].round[j].match.team_visit" id="team" label="name" track-by="name" open-direction="bottom" :options="teams" :multiple="false" :searchable="true" :internal-search="false" :clear-on-select="false" :close-on-select="true" :max-height="600" :show-no-results="false" :hide-selected="true" :placeholder="$t('selectOption')" deselectLabel="" selectLabel="" selectedLabel=""></multiselect>
            </div>
          </div>
        </div>
        <div class="pt-4">
          <b-button type="submit" block variant="primary">{{ $t('save') }}</b-button>
        </div>
      </form>
    </div>
    <hr>
    <div class="wrapper-liguilla">
      <form class="rounds" @submit.prevent="saveThirdPlace()">
        <h6 class="text-center">{{ $t('thirdPlace') }}</h6>
        <div class="match">
          <div class="team">
            <multiselect v-model="thirdPlace.team_local" id="team" label="name" track-by="name" open-direction="bottom" :options="teams" :multiple="false" :searchable="true" :internal-search="false" :clear-on-select="false" :close-on-select="true" :max-height="600" :show-no-results="false" :hide-selected="true" :placeholder="$t('selectOption')" deselectLabel="" selectLabel="" selectedLabel=""></multiselect>
          </div>
          <div class="team">
            <multiselect v-model="thirdPlace.team_visit" id="team" label="name" track-by="name" open-direction="bottom" :options="teams" :multiple="false" :searchable="true" :internal-search="false" :clear-on-select="false" :close-on-select="true" :max-height="600" :show-no-results="false" :hide-selected="true" :placeholder="$t('selectOption')" deselectLabel="" selectLabel="" selectedLabel=""></multiselect>
          </div>
        </div>
        <div class="match">
          <b-button type="submit" block variant="primary">{{ $t('send') }}</b-button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      selectedTeam: [],
      fixture: [],
      division: {},
      matches: [],
      tournamentId: this.$route.params.tournamentId,
      divisionId: this.$route.params.divisionId,
      thirdPlace: {},
      teams: [],
      frees: 0
    }
  },
  computed: {
    ...mapGetters([
      'lang',
      'console',
      'tournament'
    ])
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    setClassToRound (currentRound) {
      if (currentRound === 0) {
        return ''
      }
      if ((currentRound + 1) === this.fixture.length) {
        return ''
      }
      return `round-x-${currentRound + 1}`
    },
    saveThirdPlace () {
      let path = `auth/${this.lang}/${this.console}/tournaments/${this.tournamentId}/division/${this.divisionId}/liguilla/store/98`
      const payload = {
        matches: [{
          match: this.thirdPlace
        }]
      }
      this.$axios.post(path, payload).then(() => {
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
      })
    },
    save (round) {
      let path = `auth/${this.lang}/${this.console}/tournaments/${this.tournamentId}/division/${this.divisionId}/liguilla/store/`
      if (this.selectedTeam.length === round) {
        path = `${path}99`
      } else {
        path = `${path}${round}`
      }
      const payload = {
        matches: this.selectedTeam[round -1].round
      }
      this.$axios.post(path, payload).then(() => {
        this.$toastr.success(this.$t('updatedInfo'), window.TITLE_SUCCESS)
      })
    },
    fetchData () {
      const path = `auth/${this.lang}/${this.console}/tournaments/${this.tournamentId}/division/${this.divisionId}/liguilla`
      this.$axios.get(path).then((response) => {
        const data = response.data
        this.division = data.division
        document.title = `${this.division.name} - ${this.$t('createLiguilla')}`
        this.matches = data.matches
        this.teams = data.teams
        this.fixture = data.fixture
        this.fixture.map(f => {
          const round = []
          f.round.map(m => {
            if (m.team_visit === 'FREE') {
              this.frees+=1
            }
            round.push({
              match: {
                team_local: this.getTeamSelect(m.team_local),
                team_visit: this.getTeamSelect(m.team_visit)
              }
            })
          })
          this.selectedTeam.push({
            round
          })
        })
        this.thirdPlace = {
          team_local: this.getTeamSelect(data.third_place.team_local),
          team_visit: this.getTeamSelect(data.third_place.team_visit)
        }
        if (this.frees > 0) {
          this.frees = this.frees - 1
          if (this.frees % 2 !== 0) {
            this.frees = this.frees - 1
          }
          this.frees = this.frees / 2
        }
      })
    },
    getTeamSelect (teamId) {
      if (teamId === 'FREE') {
        return 'FREE'
      }
      let team = this.teams.filter(t => t.id === teamId)
      if (team.length > 0) {
        return team[0]
      }
      return ''
    }
  }
}
</script>
